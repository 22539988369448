import React from 'react';
import SauberOrcamento from './components/estrutura/SauberOrcamento';
import BarraSuperior from './components/estrutura/BarraSuperior';
import BarraInferior from './components/estrutura/BarraInferior';


export default function App(props) {

    return (
        <div>
            <BarraSuperior />
            <SauberOrcamento />
            <BarraInferior />
        </div>
    );
}
