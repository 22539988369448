import '../css/BarraInferior.css'
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Container } from '@mui/material';


export default function BarraInferior(props) {
    const styles = {
        customizeToolbar : {
            height: 140,
            background: '#005083',
            boxShadow: 'none',
            top: 'auto',
            bottom: 0,
            color: '#fff',
            fontSize: '20px'
        },
        toolbar : {
            height: 140,
            alignItems: 'end'
        },
        sauberLogo : {
            height: 80,
            float: 'none',
            margin: 'auto'
        },
        imgFuncionario : {
            maxWidth: '280px',
            float: 'right',
            marginBottom: '32px'
        },
        imgLogomarca : {
            height: '40px'
        },
        grid:{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        container: {
            marginBottom: '50px'
        }
    };

    return (
        <div class="barra-inferior" style={{position: 'relative', zIndex: 0}}>
            <AppBar  sx={styles.customizeToolbar}>
                <Toolbar style={styles.toolbar} maxWidth="lg">
                <Container maxWidth="lg"  sx={ styles.container }>
                    <img src='/funcionario.png' alt='Funcionário' style={styles.imgFuncionario} />
                    <Grid container spacing={2} sx={{}}>
                        <Grid item xs={4} sx={{...styles.grid, justifyContent:'left'}}>
                            <WhatsAppIcon />&nbsp;&nbsp;(77)9.9969-2009
                        </Grid>
                        <Grid item xs={4} sx={styles.grid}>
                            <InstagramIcon />&nbsp;&nbsp;SauberEnergiaSolar
                        </Grid>
                        <Grid item xs={4} sx={{...styles.grid, justifyContent:'right'}}>
                            <img src='/logo-sauber-branca.png' alt='logo' style={styles.imgLogomarca} />
                        </Grid>
                    </Grid>
                </Container>
                </Toolbar>
            </AppBar>
        </div>
    );
}