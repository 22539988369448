import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';


export default function BarraSuperior(props) {
    const styles = {
        customizeToolbar : {
            height: 15,
            background: '#a5a5a5',
            margin: '0px 0px 165px 0px',
            boxShadow: 'none'

        },
        toolbar: {
            height: 100,
            marginTop: 65
        },
        sauberLogo : {
            height: 100,
            float: 'none',
            margin: 'auto'
        },
    };

    return (
        <AppBar position="static" sx={styles.customizeToolbar}>
            <Toolbar style={styles.toolbar}>
                <img src='/logo-sauber-azul.png' style={styles.sauberLogo} alt='logo'/>
            </Toolbar>
        </AppBar>
    );
}