import '../css/SauberOrcamento.css'
import axios from "axios";
import * as React from 'react';
import { Box, Container } from '@mui/material'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const getHost = () => {
    return window.location.protocol + "//" + window.location.hostname;
}

export default function SauberOrcamento(props) {
    const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
    const [openAlertError, setOpenAlertError] = React.useState(false);
    const [enviandoForm, setEnviandoForm] = React.useState(false);
    const [nome, setNome] = React.useState('');
    const [mediaConcumo, setMediaConsumo] = React.useState('');
    const [tipoLigacao, setTipoLigacao] = React.useState('');
    const [telhado, setTelhado] = React.useState('');
    const [aumentoConsumo, setAumentoConsumo] = React.useState('');
    const [contato, setContato] = React.useState('');
    
    
    const handleChangeNome = (newAlignment) => {
        setNome(newAlignment.target.value);
    };
    const handleChangeMediaConsumo = (event, newAlignment) => {
        setMediaConsumo(newAlignment);
    };
    const handleChangeTipoLigacao = (event, newAlignment) => {
        setTipoLigacao(newAlignment);
    };

    const handleChangeTelhado = (event, newAlignment) => {
        setTelhado(newAlignment);
    };

    const handleChangeAumentoConsumo = (event, newAlignment) => {
        setAumentoConsumo(newAlignment);
    };
    const handleChangeContato = (newAlignment) => {
        setContato(newAlignment.target.value);
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validarForm()) {
            exibirAlertaErro();
            return;
        }

        setEnviandoForm(true);
        const response = await axios.post(`${getHost()}/api/enviarEmail`, {
            nome: nome,
            mediaConsumo: mediaConcumo,
            ligacaoLocal: tipoLigacao,
            telhado: telhado,
            aumentoConsumo: aumentoConsumo,
            contato: contato
        });
        setEnviandoForm(false);
        
        if(response.data.success) {
            exibirAlertaSucesso();
            setNome('');
            setMediaConsumo('');
            setTipoLigacao('');
            setTelhado('');
            setAumentoConsumo('');
            setContato('');
        } else {
            exibirAlertaErro();
        }
    }

    const validarForm = () => {
        var isFormValido = true;
        var campos = [nome, mediaConcumo, tipoLigacao, telhado, aumentoConsumo, contato];

        campos.forEach((campo) => {
            if(!campo)
                isFormValido = false;
        });

        return isFormValido;
    }

    const exibirAlertaErro = (titulo, texto) => {
        setOpenAlertError(true);

        setTimeout(() => {
            setOpenAlertError(false);
        }, 5000);
    }

    const exibirAlertaSucesso = (titulo, texto) => {
        setOpenAlertSuccess(true);

        setTimeout(() => {
            setOpenAlertSuccess(false);
        }, 7000);
    }

    const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
            {...other}
            mask="(#0) 0.0000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
            />
        );
    });
    TextMaskCustom.propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };


    const styles = {
        divqualquer : {
            backgroundImage: 'URL("/background.png")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
        },
        container : {
            // backgroundImage: 'URL("/background.png")',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'contain',
            // backgroundPosition: 'center',
            position: 'relative',
            zIndex: 1
        }
    }

    return (
        <div style={ styles.divqualquer }>
            <Container maxWidth="lg"  sx={ styles.container }>

                <Typography class="titulo-destaque" variant="h5" gutterBottom component="div" >
                        <span>ORÇAMENTO. </span>OLÁ, SEJA BEM VINDO(A)!
                </Typography>

                
                    <Stack sx={{ width: '100%', marginTop: '25px' }} spacing={2}>
                        <Collapse in={openAlertError}>
                            <Alert severity="error">
                                <AlertTitle>Todas as perguntas são obrigatórias</AlertTitle>
                                Por favor, preencha todas as perguntas. Todas são <strong>obrigatórias!</strong>
                            </Alert>
                        </Collapse>

                        <Collapse in={openAlertSuccess}>
                            <Alert severity="success">
                                <AlertTitle>Parabéns!</AlertTitle>
                                Recebemos suas informações e em breve <strong>entraremos em contato!</strong>
                            </Alert>
                        </Collapse>
                    </Stack>

                <Box component="form"  noValidate autoComplete="off" style={{fontSize: 25}} onSubmit={handleSubmit}>
                    <div class="item-form">
                        <div>Qual o seu nome?</div>
                        <TextField id="nome"  variant="outlined" fullWidth value={nome} inputProps={{style: {fontSize: 25}}} onChange={handleChangeNome} />
                    </div>
                    <div class="item-form">
                        <div>Qual a média de consumo da sua conta de energia?</div>
                        <Box>
                            <ToggleButtonGroup class="toggle-button-group-sauber" exclusive  aria-label="Platform" value={mediaConcumo} onChange={handleChangeMediaConsumo}>
                                <ToggleButton value="400" >Até 400kWh</ToggleButton>
                                <ToggleButton value="400-800" >Entre 400 e 800kWh</ToggleButton>
                                <ToggleButton value="800-1200" >Entre 800 e 1200kWh</ToggleButton>
                                <ToggleButton value="1200-1600" >Entre 1200 e 1600kWh</ToggleButton>
                                <ToggleButton value="1600+" >Acima de 1600kWh</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </div>
                    <div class="item-form">
                        <div>Escolha a opção que caracteriza a ligação do local</div>
                        <Box>
                            <ToggleButtonGroup class="toggle-button-group-sauber" exclusive  aria-label="Platform" value={tipoLigacao} onChange={handleChangeTipoLigacao}>
                                <ToggleButton value="monofasico" >Monofásico</ToggleButton>
                                <ToggleButton value="trifasico" >Trifásico</ToggleButton>
                                <ToggleButton value="naosei" >Não sei dizer</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </div>
                    <div class="item-form">
                        <div>Qual o tipo de telhado predominante do local?</div>
                        <Box>
                            <ToggleButtonGroup class="toggle-button-group-sauber" exclusive colo aria-label="Platform" value={telhado} onChange={handleChangeTelhado}>
                                <ToggleButton value="metalico" >Metálico</ToggleButton>
                                <ToggleButton value="ceramico" >Cerâmico</ToggleButton>
                                <ToggleButton value="fibrocimento" >Fibrocimento</ToggleButton>
                                <ToggleButton value="laje" >Laje</ToggleButton>
                                <ToggleButton value="solo" >Instalação em solo</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </div>
                    <div class="item-form">
                        <div>Acredita que o consumo irá aumentar nos próximos anos?</div>
                        <Box>
                            <ToggleButtonGroup class="toggle-button-group-sauber" exclusive  aria-label="Platform" value={aumentoConsumo} onChange={handleChangeAumentoConsumo}>
                                <ToggleButton value="sim" >Sim</ToggleButton>
                                <ToggleButton value="nao" >Não</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </div>
                    <div class="item-form" style={{width: '60%'}}>
                        <div>Para finalizar, coloque abaixo o seu contato para enviarmos o seu orçamento</div>
                        <TextField id="nome2"  variant="outlined" fullWidth value={contato} inputProps={{style: {fontSize: 25}}} onChange={handleChangeContato} />
                        <Input  type="hidden" value={contato} onChange={handleChangeContato} inputComponent={TextMaskCustom} />
                    </div>

                    <div class="item-form">
                        <Button class="botao-acao" variant="contained" type="submit">ENVIAR SOLICITAÇÃO</Button>
                    </div>
                </Box>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={enviandoForm}>
                    <CircularProgress color="inherit" />
                </Backdrop>

            </Container>
        </div>
    );
}